<template>
  <div>
    <editor-content :editor="editor" class="editor" />
  </div>
</template>
<script>
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Collaboration from "@tiptap/extension-collaboration";
import { HocuspocusProvider } from "@hocuspocus/provider";

export default {
  data() {
    return {
      editor: null,
    };
  },
  // <vue-component url="https://ms.ytdevops.com/a8407493-9a8c-4faf-b2bc-6c9ab8faf19e/home/my_file"></vue-component>

  mounted() {
    const provider = new HocuspocusProvider({
      url: "ws://39.104.70.20:1234",
      name: "example-document",
    });
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          // The Collaboration extension comes with its own history handling
          history: false,
        }),
        // Register the document with Tiptap
        Collaboration.configure({
          document: provider.document,
        }),
      ],
    });
  },
};
// Set up the Hocuspocus WebSocket provider
</script>
